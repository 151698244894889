import React, { Fragment, useState, useEffect, useRef } from "react";
import PrestiageRide from "../assets/PrestiageRide.svg";
import { useParams } from "react-router-dom";
import creditCardType from "credit-card-type";
import { GoShieldCheck } from "react-icons/go";
import LocationInputs2 from "../components/googleInput";
import { useCreditCardValidator } from "react-creditcard-validator";
import { formatCreditCardNumber, cardTypeMap } from "../utils/Utils";
import valid from "card-validator";
import MuiPhoneNumber from "material-ui-phone-number";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import GIF from "../assets/Loader2.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InvoiceLink = () => {
  const { id } = useParams();
  const [reservation, setReservation] = useState("");
  const [reservations, setReservations] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [errorCheck, setErrorCheck] = useState(false);
  const [errorObject, setErrorObject] = useState({
    cardNumber: "",
  });

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const cancelButtonRef2 = useRef(null);
  const [basicModalOpen, setBasicModalOpen] = useState(false);

  const [loaded, setLoaded] = useState(false);

  const [cardState, setCardState] = useState({
    cardHolderName: "",
    cardNumber: "",
    cardType: "",
    cvc: "",
    expirationMonthYear: "",
    cardHolderEmail: "",
    cardHolderPhone: "",
  });

  function expDateValidate(month, year) {
    // if (Number(year) > 2035) {
    //   return "Expiry Date Year cannot be greater than 2035";
    // }
    return;
  }

  function cleanCardNumber(cardNumber) {
    return cardNumber?.replace(/\D/g, ""); // Remove non-numeric characters
  }

  const handleChangePaymentCard = (e) => {
    const { name, value } = e.target;
    setCardState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCardNumberBlur = (e) => {
    const cleanedCardNumber = cleanCardNumber(cardState.cardNumber);
    let creditCard = valid.number(cleanedCardNumber);
    if (cardState.cardNumber === null || !cardState.cardNumber?.trim()) {
      setErrorObject({
        cardNumber: "Enter a number",
      });
    } else if (creditCard.isValid) {
      setErrorObject({
        cardNumber: "",
      });
    } else {
      setErrorObject({
        cardNumber: "Number is invalid",
      });
    }
  };

  const {
    getCardNumberProps,
    getCVCProps,
    getExpiryDateProps,
    meta: { erroredInputs },
  } = useCreditCardValidator({ expiryDateValidator: expDateValidate });

  useEffect(() => {
    getSingleReservation();
  }, []);

  console.log(reservation, "reservation--reservation");
  const getSingleReservation = async () => {
    try {
      setLoaded(true);
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/email-template/payment-link-verification-invoice/${id}`
      );

      console.log(result, "result");
      if (result?.data?.success === true) {
        setReservation(result?.data);
        setReservations(result?.data?.reservation);
      } else if (result?.data?.success === false) {
        setErrorCheck(true);
        setErrorMessage(result?.data?.message);
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoaded(false);
    }
  };

  const checkValidation = () => {
    let valid = true;
    const mandatoryFields = ["cardNumber", "expirationMonthYear", "cvc"];

    if (!reservation?.reservation?.PaymentMethod) {
      for (const key of mandatoryFields) {
        if (!cardState[key]) {
          alert("Please fill all required fields");
          valid = false;
          break; // Exit the loop immediately
        }
      }
    }

    return valid;
  };

  const handleSubmit = async () => {
    const result = checkValidation();

    if (result) {
      try {
        setLoaded(true);
        const input = cardState?.expirationMonthYear;
        const [month, year] = input.split(" / ").map((item) => item.trim());

        // Dynamically determine the century prefix
        const currentYear = new Date().getFullYear();
        const centuryPrefix = Math.floor(currentYear / 100) * 100;

        // Combine the century prefix with the year
        const fullYear = centuryPrefix + parseInt(year, 10);

        const formattedDate = `${fullYear}-${month}`;
        // let body = {};

        // if (reservation?.type === "Authorize & Capture") {
        //   body = {
        //     createTransactionRequest: {
        //       merchantAuthentication: {
        //         name:
        //           reservation?.booking?.transaction?.length > 0 &&
        //           reservation?.booking?.transaction?.[0]?.merchant ===
        //             "Elvn Merchant"
        //             ? "5KP3u95bQpv"
        //             : "5KP3u95bQpv",
        //         // ? "95ExP7ceP"
        //         // : "65dyWY3x",

        //         transactionKey:
        //           reservation?.booking?.transaction?.length > 0 &&
        //           reservation?.booking?.transaction?.[0]?.merchant ===
        //             "Elvn Merchant"
        //             ? "346HZ32z3fP4hTG2"
        //             : "346HZ32z3fP4hTG2",
        //         // ? "5F9W4gGTL9w82eeC"
        //         // : "729ru46A86Qt4WWC",
        //       },
        //       refId: "123456",
        //       transactionRequest: {
        //         transactionType: "authCaptureTransaction",
        //         amount: reservation?.amount,
        //         payment: {
        //           creditCard: {
        //             cardNumber: cardState?.cardNumber?.replace(/\s+/g, ""),
        //             expirationDate: formattedDate,
        //             cardCode: cardState?.cvc,
        //           },
        //         },
        //         order: {
        //           invoiceNumber: reservation?.booking?.reservation?.orderNo,
        //         },
        //         billTo: {
        //           firstName: cardState?.cardHolderName,
        //           address: cardState.billingAddress,
        //           city: cardState?.city,
        //           state: cardState?.state,
        //           zip: cardState?.zipcode,
        //           country: cardState?.country,
        //         },
        //       },
        //     },
        //   };
        // } else if (reservation?.type === "Authorize") {
        //   body = {
        //     createTransactionRequest: {
        //       merchantAuthentication: {
        //         name:
        //           reservation?.booking?.transaction?.length > 0 &&
        //           reservation?.booking?.transaction[0]?.merchant ===
        //             "Elvn Merchant"
        //             ? "5KP3u95bQpv"
        //             : "5KP3u95bQpv",
        //         // ? "95ExP7ceP"
        //         // : "65dyWY3x",
        //         transactionKey:
        //           reservation?.booking?.transaction?.length > 0 &&
        //           reservation?.booking?.transaction[0]?.merchant ===
        //             "Elvn Merchant"
        //             ? "346HZ32z3fP4hTG2"
        //             : "346HZ32z3fP4hTG2",
        //         // ? "95ExP7ceP"
        //         // : "65dyWY3x",
        //       },
        //       refId: "123456",
        //       transactionRequest: {
        //         transactionType: "authOnlyTransaction",
        //         amount: reservation?.amount,
        //         payment: {
        //           creditCard: {
        //             cardNumber: cardState?.cardNumber?.replace(/\s+/g, ""),
        //             expirationDate: formattedDate,
        //             cardCode: cardState?.cvc,
        //           },
        //         },
        //         order: {
        //           invoiceNumber: reservation?.booking?.reservation?.orderNo,
        //         },
        //         billTo: {
        //           firstName: cardState?.cardHolderName,
        //           address: cardState.billingAddress,
        //           city: cardState.city,
        //           state: cardState.state,
        //           zip: cardState.zipcode,
        //           country: cardState.country,
        //         },
        //       },
        //     },
        //   };
        // }

        let finalData = {
          paymentDetails: {
            cardNumber: cardState?.cardNumber?.replace(/\s+/g, ""),
            expirationDate: formattedDate,
            cvv: cardState?.cvc,
            paymentAmount: reservation?.amount,
            invoiceNumber: reservation?.invoice?.invoiceNumber,
            description: "",
            type: reservation.type,
            cardHolderName: cardState?.cardHolderName,
            cardHolderEmail: cardState?.cardHolderEmail,
            cardType: cardState?.cardType,
            payWith: "",
            merchant:
              (reservation?.reservationId?.length > 0 &&
                reservation?.reservationId[0]?.merchant) ||
              "Elvn Merchant",
            paymentNotes:
              (reservation?.reservationId?.length > 0 &&
                reservation?.reservationId[0]?.paymentNotes) ||
              "",
            paymentMethod: "CC",
            street: cardState?.street || "",
            address: cardState.billingAddress,
            city: cardState?.city,
            state: cardState?.state,
            zip: cardState?.zipcode,
            country: cardState?.country,
          },
          idempotencyKey: `payment-${Date.now()}-${Math.random()
            .toString(36)
            .substr(2, 9)}`,
          transactionId: reservation?.transaction || "",
          reservationPayments: reservation?.reservation || [],
          reservationTransactionId: reservation?.reservationId || [],
        };

        let transactionResult;

        const initialState = {
          billingAddress: "",
          cardHolderEmail: "",
          cardHolderName: "",
          cardHolderPhone: "",
          cardNumber: "",
          cardType: "",
          city: "",
          country: "",
          cvc: "",
          expirationMonthYear: "",
          state: "",
          street: "",
          transactionId: "",
          type: "",
          zipcode: "",
        };

        console.log(finalData, "finalData-finalData");

        if (reservation?.type === "Authorize & Capture") {
          transactionResult = await axios.post(
            `${process.env.REACT_APP_API_URL}/api-v2/invoice-transaction/payment-process-auth-capture/${reservation?.invoice?._id}`,
            finalData
          );

          console.log(transactionResult, finalData, "acccc");
        }
        // else if (reservation?.type === "Authorize") {
        //   transactionResult = await axios.post(
        //     `${process.env.REACT_APP_API_URL}/api-v2/transaction/invoice-authorize-only-payment-link/${reservation?.invoice?._id}`,
        //     finalData
        //   );
        //   console.log(transactionResult, finalData, "aaaa");
        // }
        if (transactionResult?.data?.success === true) {
          setOpen(true);
          setCardState(initialState);
        } else if (
          transactionResult?.data?.success === false &&
          transactionResult?.data?.status === "expired"
        ) {
          setBasicModalOpen(true);
        } else if (
          transactionResult?.data?.success === false &&
          transactionResult?.data?.details
        ) {
          toast.error(transactionResult?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.log(error, "submiterror");
      } finally {
        setLoaded(false);
      }
    }
  };

  return (
    <div className="lg:py-2 py-0 bg-white">
      <ToastContainer />
      <Backdrop
        sx={{ color: "#000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaded}
      >
        {loaded && (
          <div style={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                color: "white",
                top: "40px",
                left: "-50px",
                fontWeight: "600",
                width: "800%",
              }}
            >
              {/* {store?.text} */}
            </span>
            <img
              src={GIF}
              alt="Loading"
              style={{ width: "90px", height: "90px" }}
            />
          </div>
        )}
      </Backdrop>
      {errorCheck ? (
        <div className="px-4">
          <div className="bg-red-300 py-2 rounded-md">
            <h1 className="text-red-900 text-center font-bold">
              {errorMessage}
            </h1>
          </div>
        </div>
      ) : (
        <>
          <div className="mx-auto max-w-7xl border border-black rounded lg:px-8 px-4 py-4">
            <div className="">
              <h1 className="text-lg font-bold">
                <img src={PrestiageRide} alt="" />
              </h1>
              <div className="mt-2 text-[#2B3252]">
                <a href="tel:+ 1 (888) 346 9886">
                  <p>Tel: + 1 (888) 346 9886</p>
                </a>
                <a href="mailto:contact@prestigeride.com">
                  <p>Email: contact@prestigeride.com</p>
                </a>
              </div>
            </div>
            <div className="mt-4">
              <h1 className="text-2xl font-semibold text-[#2B3252]">
                Submit Payment Info
              </h1>
            </div>
            <div className="mt-2 border-t border-b border-black py-2 text-[#2B3252]">
              <h2 className="bg-gray-200 font-semibold px-4 py-2">
                Invoice No # {reservation?.invoice?.invoiceNumber}
              </h2>
            </div>
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-1">
              <div className="inline-block min-w-full py-2 align-middle px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black/5 rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-[#D5AF34]">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-[#2B3252] sm:pl-6"
                        >
                          Conf #
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-xs font-semibold text-[#2B3252]"
                        >
                          Service Type
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-xs font-semibold text-[#2B3252]"
                        >
                          PU Date & Time
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-xs font-semibold text-[#2B3252]"
                        >
                          Routing Information
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-xs font-semibold text-[#2B3252]"
                        >
                          Trip Total
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-xs font-semibold text-[#2B3252]"
                        >
                          Due Total
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-xs font-semibold text-[#2B3252]"
                        >
                          Charge Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {reservations?.map((person, index) => {
                        return (
                          <tr key={index}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-[#2B3252] sm:pl-6">
                              {person?.reservation?.reservation?.orderNo}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-xs text-[#2B3252]">
                              {person?.reservation?.reservation?.service}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-xs text-[#2B3252]">
                              {person?.reservation?.reservation?.date} <br />{" "}
                              {person?.reservation?.reservation?.time}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-xs text-[#2B3252]">
                              PU:
                              {person?.reservation?.reservation?.pickUpLocation}
                              <br />
                              DO:
                              {
                                person?.reservation?.reservation
                                  ?.dropOffLocation
                              }
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-xs text-[#2B3252]">
                              $
                              {
                                person?.reservation?.reservation
                                  ?.calculatedValues?.total
                              }
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-xs text-[#2B3252]">
                              $
                              {
                                person?.reservation?.reservation
                                  ?.calculatedValues?.due
                              }
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-xs text-[#2B3252]">
                              ${person?.amount}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* </div> */}

            <div className="py-2">
              <h2 className="bg-gray-200 font-semibold text-[#2B3252] px-4 py-2">
                Payment Information
              </h2>
            </div>
            <div className="py-2">
              <div className="">
                <label
                  htmlFor=""
                  className="block text-[13px] font-bold text-[#2B3252]"
                >
                  CardHolder Name
                </label>
                <div>
                  <input
                    type="text"
                    name="cardHolderName"
                    id="cardHolderName"
                    // placeholder="Name on Card"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setCardState((prev) => ({
                        ...prev,
                        cardHolderName: inputValue,
                      }));
                    }}
                    value={cardState.cardHolderName}
                    className="block w-full h-[34px] px-2 text-[14px] rounded border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-y-0 gap-y-2 gap-x-2 mt-2">
                <div>
                  <label
                    htmlFor=""
                    className="block text-[13px] font-bold text-[#2B3252]"
                  >
                    Card Number <span className="text-red-600">*</span>
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="cardNumber"
                      data-testid="cardNumber"
                      id="cardNumber"
                      placeholder="0000 0000 0000 0000"
                      className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                      value={cardState.cardNumber}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // Limit input to 16 characters
                        if (inputValue.length <= 19) {
                          var visaCards = creditCardType(inputValue || []);
                          // Update card state
                          setCardState((prev) => ({
                            ...prev,
                            cardNumber: formatCreditCardNumber(inputValue),
                            cardType:
                              Array.isArray(visaCards) &&
                              visaCards?.length > 0 &&
                              visaCards[0]?.type
                                ? cardTypeMap[visaCards[0]?.type] ||
                                  visaCards[0].type
                                : "",
                          }));
                          setErrorObject({
                            cardNumber: "",
                          });
                        }
                      }}
                      onBlur={handleCardNumberBlur}
                    />
                    <small className="text-red-500 text-[14px]">
                      {errorObject.cardNumber ? errorObject.cardNumber : null}
                    </small>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-x-2">
                  <div className="">
                    <label
                      htmlFor=""
                      className="block text-[13px] font-bold text-[#2B3252]"
                    >
                      Expiry Month/Year <span className="text-red-600">*</span>
                    </label>
                    <div className="">
                      <input
                        {...getExpiryDateProps({
                          onChange: (e) => {
                            setCardState((prev) => ({
                              ...prev,
                              expirationMonthYear: e.target.value,
                            }));
                          },
                        })}
                        type="text"
                        name="expirationMonthYear"
                        id="cardExpiration"
                        data-testid="cardExpiration"
                        placeholder="MM/YY"
                        className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                        value={cardState.expirationMonthYear}
                      />
                      <small className="text-red-500 text-[14px]">
                        {erroredInputs.expiryDate && erroredInputs.expiryDate}
                      </small>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor=""
                      className="block text-[13px] font-bold text-[#2B3252]"
                    >
                      CVC <span className="text-red-600">*</span>
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="cvc"
                        id="cardSecurityCode"
                        data-testid="cardSecurityCode"
                        className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                        value={cardState.cvc}
                        maxLength={4}
                        onChange={(e) => {
                          let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                          value = value.substring(0, 4); // Ensure it doesn't exceed 4 digits
                          setCardState((prev) => ({
                            ...prev,
                            cvc: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 grid grid-cols-2 gap-x-2">
                <div className="text-[15px] leading-5 flex items-center space-x-2 text-[#2B3252]">
                  <span className="text-2xl">
                    <GoShieldCheck />
                  </span>
                  <span>All transactions are safe and secure.</span>
                </div>
                <div className="w-full py-2 bg-gray-100 flex justify-center items-center font-semibold rounded border border-black text-[#2B3252]">
                  Amount: $
                  {reservation?.amount
                    ? parseFloat(reservation?.amount)?.toFixed(2)
                    : "0.00"}
                </div>
              </div>
            </div>
            <div className="py-2">
              <h2 className="bg-gray-200 font-semibold text-[#2B3252] px-4 py-2">
                Billing Information
              </h2>
            </div>
            <div className="py-2">
              <div className="mt-1 grid grid-cols-2 gap-x-2">
                <div>
                  <label
                    htmlFor=""
                    className="block text-[13px] font-bold text-[#2B3252]"
                  >
                    Billing Address
                  </label>
                  <div className="">
                    <LocationInputs2
                      setCardState={setCardState}
                      cardState={cardState}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor=""
                    className="block text-[13px] font-bold text-[#2B3252]"
                  >
                    Street Address
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="street"
                      id="text"
                      className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                      onChange={handleChangePaymentCard}
                      value={cardState.street}
                    />
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-4 grid-cols-2 gap-x-2 lg:gap-y-0 gap-y-2 mt-2">
                <div>
                  <label
                    htmlFor=""
                    className="block text-[13px] font-bold text-[#2B3252]"
                  >
                    City
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="city"
                      id="text"
                      className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                      onChange={handleChangePaymentCard}
                      value={cardState.city}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor=""
                    className="block text-[13px] font-bold text-[#2B3252]"
                  >
                    State/Province
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="state"
                      id="text"
                      className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                      onChange={handleChangePaymentCard}
                      value={cardState.state}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor=""
                    className="block text-[13px] font-bold text-[#2B3252]"
                  >
                    Zip/Post
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="zipcode"
                      id="text"
                      className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                      onChange={handleChangePaymentCard}
                      value={cardState.zipcode}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor=""
                    className="block text-[13px] font-bold text-[#2B3252]"
                  >
                    Country
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="country"
                      id="text"
                      className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                      onChange={handleChangePaymentCard}
                      value={cardState.country}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-2 mt-2">
                <div className="">
                  <label
                    htmlFor=""
                    className="block text-[13px] font-bold text-[#2B3252]"
                  >
                    CardHolder Email
                  </label>
                  <div>
                    <input
                      type="text"
                      name="cardHolderEmail"
                      id="cardHolderEmail"
                      // placeholder="Cardholder Email"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        setCardState((prev) => ({
                          ...prev,
                          cardHolderEmail: inputValue,
                        }));
                      }}
                      value={cardState.cardHolderEmail}
                      className="block w-full h-[34px] px-2 text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                    />
                  </div>
                </div>
                <div className="">
                  <label
                    htmlFor=""
                    className="block text-[13px] font-bold text-[#2B3252]"
                  >
                    CardHolder Phone
                  </label>
                  <div className="border border-black rounded-[4px] h-[34px]">
                    <MuiPhoneNumber
                      defaultCountry={"us"}
                      name="cardHolderPhone"
                      value={cardState.cardHolderPhone}
                      className="block w-full h-[30px] text-[14px] rounded-[4px] border border-black text-black shadow-sm placeholder:text-gray-400 focus:border-none focus:ring-1 focus:ring-inset focus:ring-black"
                      onChange={(value) => {
                        setCardState((prev) => ({
                          ...prev,
                          cardHolderPhone: value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <button
                  className="w-full rounded py-1.5 bg-[#D5AF34] text-white text-lg font-semibold"
                  onClick={handleSubmit}
                >
                  Submit Payment Info
                </button>
              </div>
              <div className="mt-2">
                <p className="text-[#2B3252]">
                  By continuing you agree to our{" "}
                  <a
                    href="https://www.prestigeride.com/privacy-and-cookies-policy"
                    target="_blank"
                    className="text-[#D5AF34] font-semibold"
                  >
                    privacy policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.prestigeride.com/terms-and-condition"
                    target="_blank"
                    className="text-[#D5AF34] font-semibold"
                  >
                    terms and conditions.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {open === true ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-[450px] max-w-3xl sm:p-6">
                    <div>
                      <div className="mx-auto flex items-center justify-center mt-[-60px]">
                        <svg
                          width="100"
                          height="100"
                          viewBox="0 0 100 100"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="50"
                            cy="50"
                            r="47.5"
                            fill="#F5F5F5"
                            stroke="white"
                            stroke-width="5"
                          />
                          <path
                            d="M50 85C43.0777 85 36.3108 82.9473 30.5551 79.1014C24.7993 75.2556 20.3133 69.7893 17.6642 63.3939C15.0152 56.9985 14.322 49.9612 15.6725 43.1718C17.023 36.3825 20.3564 30.1461 25.2513 25.2513C30.1461 20.3564 36.3825 17.023 43.1718 15.6725C49.9612 14.322 56.9985 15.0152 63.3939 17.6642C69.7893 20.3133 75.2556 24.7993 79.1014 30.5551C82.9473 36.3108 85 43.0777 85 50C85 59.2826 81.3125 68.185 74.7487 74.7487C68.185 81.3125 59.2826 85 50 85ZM50 19.6667C44.0006 19.6667 38.136 21.4457 33.1477 24.7788C28.1594 28.1118 24.2715 32.8493 21.9757 38.3919C19.6798 43.9346 19.0791 50.0337 20.2495 55.9178C21.4199 61.8018 24.3089 67.2067 28.5511 71.4489C32.7933 75.6911 38.1982 78.5801 44.0823 79.7505C49.9664 80.9209 56.0654 80.3202 61.6081 78.0243C67.1508 75.7285 71.8882 71.8406 75.2213 66.8523C78.5543 61.864 80.3333 55.9994 80.3333 50C80.3333 41.9551 77.1375 34.2397 71.4489 28.5511C65.7603 22.8625 58.0449 19.6667 50 19.6667Z"
                            fill="#2B3252"
                          />
                          <path
                            d="M44.5633 64C44.2434 63.999 43.9271 63.9322 43.6341 63.8037C43.341 63.6753 43.0776 63.4879 42.86 63.2533L31.9633 51.5867C31.591 51.1275 31.4069 50.5441 31.4481 49.9544C31.4893 49.3647 31.7527 48.8126 32.1853 48.4097C32.6179 48.0068 33.1872 47.7831 33.7784 47.7837C34.3695 47.7844 34.9384 48.0094 35.37 48.4133L44.5633 58.2367L54.5967 47.4917L64.63 36.7467C64.8315 36.4982 65.0819 36.2938 65.3657 36.1462C65.6495 35.9986 65.9606 35.9109 66.2797 35.8886C66.5989 35.8663 66.9192 35.9099 67.2207 36.0166C67.5223 36.1233 67.7987 36.2909 68.0328 36.5089C68.2668 36.727 68.4536 36.9908 68.5814 37.2841C68.7092 37.5773 68.7753 37.8937 68.7757 38.2136C68.7761 38.5335 68.7106 38.8501 68.5835 39.1436C68.4564 39.4372 68.2702 39.7014 68.0367 39.92L46.2667 63.2533C46.0491 63.4879 45.7856 63.6753 45.4926 63.8037C45.1996 63.9322 44.8833 63.999 44.5633 64Z"
                            fill="#2B3252"
                          />
                        </svg>
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-bold leading-6 text-[#2B3252]"
                        >
                          THANK YOU
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-md text-[#2B3252]">
                            Your payment has been submitted successfully.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}

      {basicModalOpen === true ? (
        <Transition.Root show={basicModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            initialFocus={cancelButtonRef2}
            onClose={setBasicModalOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-[450px] max-w-3xl sm:p-6">
                    <div>
                      <p
                        className="absolute -top-5 bg-gray-200 cursor-pointer -right-5 px-4 py-2 text-lg font-bold rounded-[100%]"
                        onClick={() => setBasicModalOpen(false)}
                      >
                        x
                      </p>

                      <div className="text-center">
                        <p className="text-md text-red-600">
                          Invalid Attempts, your link has been expired
                        </p>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
    </div>
  );
};

export default InvoiceLink;
